@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "../../styles/_animations";

html {
  overflow-y: auto;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  animation: fadein 1s;
  position: relative;
}

main {
  margin: 0;
  max-width: 100%;
  width: 100%;
  font-family: "Nunito";
}

// .content-platform-desktop {
//   position: relative;
//   min-height: calc(100vh - 64px);
//   margin-top: $header-height-desktop;
//   margin-bottom: 0;
//   z-index: 1;
//   background-color: #eeeeee;
//   transition: 0.2s all ease-in-out;

//   &.active-email-verify {
//     margin-top: 117px;

//     @media (min-width: 768px) and (max-width: 1250px) {
//       margin-top: 169px;
//     }
//   }

//   &.mask-active {
//     z-index: 8;
//   }

//   &.drawer-open {
//     width: calc(100% - #{$drawer-opened-width});
//     margin-left: $drawer-opened-width;
//   }

//   &.drawer-closed {
//     width: calc(100% - #{$drawer-closed-width});
//     margin-left: $drawer-closed-width;
//   }
// }

// .content-platform-mobile {
//   position: relative;
//   margin-top: $header-height-mobile;
//   width: 100%;
//   background-color: #eeeeee;

//   &.active-email-verify {
//     margin-top: $header-height-mobile;

//     @media (max-width: 767px) {
//       margin-top: 153px;
//     }

//     @media (min-width: 768px) and (max-width: 1023px) {
//       margin-top: 147px;
//     }
//   }

//   &.mask-active {
//     z-index: 8;
//   }
// }

.mask-search {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: $color-black;
  opacity: 0.3;
  left: 0;
  top: 0;
  transition: left 20s linear;
}

.input {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 16px;
  height: 36px;
}

.animation-like {
  z-index: 2;
  color: white;
  position: relative;
  animation: 0.6s;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    margin-left: -2px;
    margin-top: -2px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    transform: scale(0);
    color: #0076ff;
    border: 1px solid transparent;
    box-shadow: -0.8em 0 0 -2px, 0.8em 0 0 -2px, 0 -0.8em 0 -2px, 0 0.8em 0 -2px, -0.6em -0.6em 0 -2px,
      -0.6em 0.6em 0 -2px, 0.6em -0.6em 0 -2px, 0.6em 0.6em 0 -2px;
  }

  &:before {
    animation: effect-01-animation 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:after {
    animation: effect-02-animation 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@keyframes effect-01-animation {
  from {
    transform: rotate(-15deg) scale(0);
  }
  40% {
    opacity: 1;
  }
  to {
    transform: rotate(-30deg) scale(2.5);
    opacity: 0;
  }
}

@keyframes effect-02-animation {
  from {
    transform: rotate(10deg) scale(0);
  }
  40% {
    opacity: 1;
  }
  to {
    transform: rotate(30deg) scale(2);
    opacity: 0;
  }
}
