.fadein-1 {
  animation: fadein 1s;
}

.fadein-05 {
  animation: fadein 0.5s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes warning-desktop {
  0% {
    background: #999fa3;
  }
  50% {
    background: #db001b;
  }
  100% {
    background: #999fa3;
  }
}

@keyframes warning-touch {
  0% {
    color: #999fa3;
  }
  50% {
    color: #db001b;
  }
  100% {
    color: #999fa3;
  }
}
